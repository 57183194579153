import FinancialTransactionHeadsService from "@/services/finance/FinancialTransactionHeadsService";
import FinancialPaymentTypeService from "@/services/finance/payment-type/FinancialPaymentTypeService";
import FinancialHistoryService from "@/services/finance/history/FinancialHistoryService";
import InvoiceService from "@/services/invoice/InvoiceService";

const invoiceService = new InvoiceService();
const historyService = new FinancialHistoryService();
const paymentTypeService = new FinancialPaymentTypeService();
const financialTransactionHeadsService = new FinancialTransactionHeadsService();

const FinanceMixin = {
    data() {
        return {
            financial_heads: [],
            financial_payment_types: [],
            invoice: null,
            page: null,
            perPage: null,
            total: null,
            setting: {},
            financial_payment_type: {},
            search: {},
            current_balance: null
        }
    },
    computed: {
        invoice_id() {
            return this.$route.params.id;
        }
    },
    methods: {
        getInvoice() {
            if(this.invoice_id) {
                invoiceService.getInvoice(this.invoice_id).then(response => {
                    this.invoice = response.data.data
                }).catch(() => {
                    this.invoice = null;
                });
            }
        },

        getTransactionHeadings() {
            financialTransactionHeadsService
                .paginate(this.search)
                .then(response => {
                    this.financial_heads = response.data.data;
                })
                .catch(() => {
                });
        },

        getAllActiveTransactionHead(type) {
            financialTransactionHeadsService
                .allActiveByType(type)
                .then(response => {
                    this.financial_heads = response.data;
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        getPaymentType(event) {
            let financial_transaction_head_id = event.id
            if(event.type == 'invoice') {
                this.invoiceItem.financial_transaction_head_id = event.id
                this.invoiceItem.transaction_head_title = event.title
            } else {
                this.history_item.financial_transaction_head_id = event.id
                this.history_item.transaction_head_title = event.title
            }
            paymentTypeService
                .allActiveByTransactionHead(financial_transaction_head_id)
                .then(response => {
                    this.financial_payment_types = response.data.data;
                })
                .catch(() => {});
        },

        getPaymentTypeById(id, type = 'finance') {
            if(id != 'other') {
                paymentTypeService
                    .show(id)
                    .then(response => {
                        this.financial_payment_type = response.data.paymentType;
                        if(type == 'invoice') {
                            this.invoiceItem.quantity = 1;
                            this.invoiceItem.discount_amount = 0;
                            this.invoiceItem.payment_type_title = this.financial_payment_type.title
                            this.invoiceItem.sub_total = this.financial_payment_type.amount;
                            this.invoiceItem.item_price = this.financial_payment_type.amount;
                        } else {
                            this.history_item.quantity = 1;
                            this.history_item.payment_type_title = this.financial_payment_type.title
                            this.history_item.item_price = this.financial_payment_type.amount;
                        }
                        this.calculateTotal();
                    })
                    .catch(() => {});
            }
        },

        getHistories(search, index) {
            historyService.paginate(search, index).then(response => {
                this.transaction_histories = response.data.data
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            })
        }
    },
}
export default FinanceMixin;
