<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Manage invoice payment</h4>
                </div>
                <div class="breadcrumb-right"  v-if="invoice && !invoice.is_clear">
                  <div class="card-toolbar">

                    <v-btn  @click="addInvoicePayment()" class="mt-4 ml-2 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-add</v-icon>&nbsp;
                     Add invoice payment
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body ">
              <div class="">
                <table class="table">
                  <thead>
                    <tr class="px-3">
                      <th class="px-3">Transaction ID</th>
                      <th class="px-3">Title</th>
                      <th class="px-3">Payment date</th>
                      <th class="px-3">Payment type</th>
                      <th class="px-3">Amount</th>
                      <th class="pr-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="invoicePayments.length > 0" v-for="(item, index) of invoicePayments" :key="index">
                      <td class="px-3">
                          {{ item.transaction_id}}
                      </td>

                      <td class="px-3">
                          {{ item.title}}
                      </td>

                      <td class="px-3">
                          {{ item.formatted_payment_date}}
                      </td>

                      <td class="px-3">
                          {{ item.payment_type_text}}
                      </td>

                      <td class="px-3">
                        {{ item.amount }}
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editInvoicePayment(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fa fa-pen"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="deleteInvoicePayment(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fa fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="invoicePayments.length == 0">
                      <td colspan="12" class="text-center">
                        <strong>Data not available</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-pagination  v-if="invoicePayments.length > 0"
                               @input="getAllInvoicePayments"  class="pull-right mt-7"
                               v-model="page"
                               :total-rows="total"
                               :per-page="perPage"
                               first-number
                               last-number
                ></b-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>

    <create-and-update ref="create-and-update" @refresh="getAllInvoicePayments" @refresh-invoice="refreshInvoice"></create-and-update>
  </v-app>
</template>
<script>

import InvoicePaymentService from "@/services/invoice/payment/InvoicePaymentService";
import CreateAndUpdate from "./CreateAndUpdate.vue";
import FinanceMixin from "@/mixins/FinanceMixin";

const invoicePayment = new InvoicePaymentService();

export default {
  name: "InvoicePayment",
  props: ['invoice'],
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      invoicePayments: [],
      total: null,
      perPage: null,
      page: null,
      menuStartDate: false,
      menuEndDate: false,
      search: {
        title: '',
        invoice_id: '',
      },
    };
  },
  computed: {
    invoiceId() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getAllInvoicePayments();
  },

  methods: {
    refreshInvoice() {
      this.$emit('refresh')
    },

    getAllInvoicePayments(){
      invoicePayment.paginate(this.invoiceId, this.search, this.page).then(response => {
        this.invoicePayments = response.data.data
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).catch(() => {
        this.invoicePayments = [];
      })
    },

    addInvoicePayment(){
      this.$refs['create-and-update'].createInvoicePayment()
    },

    editInvoicePayment(item){
      this.$refs['create-and-update'].editInvoicePayment(item)
    },

    deleteInvoicePayment(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            invoicePayment.delete(this.invoiceId, id).then((response) => {
              if(response.data.status == "OK") {
                this.$snotify.success("Delete successfully ");
                this.getAllInvoicePayments();
                this.getInvoice();
              }
            }).catch(() => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },
  }
};
</script>
