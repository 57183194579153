import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class InvoicePaymentService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin/invoice`;
    }

    paginate(invoiceId, data={},index = null) {
        let url = `${this.#api}/${invoiceId}/payment`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    store(invoiceId, data) {
        let url = `${this.#api}/${invoiceId}/payment`;
        return apiService.post(url, data);
    }

    update(invoiceId, id, data) {
        let url = `${this.#api}/${invoiceId}/payment/${id}`
        return apiService.put(url, data);
    }

    delete(invoiceId, id) {
        let url = `${this.#api}/${invoiceId}/payment/${id}`
        return apiService.delete(url);
    }

}
