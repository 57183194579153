<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} invoice payment</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                  v-model="invoicePayment.title"
                  :error="$v.invoicePayment.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.invoicePayment.title.$error">Title is required.</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-text-field
                  v-model="invoicePayment.transaction_id"
                  :error="$v.invoicePayment.transaction_id.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Transaction ID/ Receipt no <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.invoicePayment.title.$error">This field is required.</span>
              <span class="text-danger" v-if="errors.transaction_id" >* {{ errors.transaction_id[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="invoicePayment.payment_date"
                      prepend-inner-icon="mdi-calendar"
                      prepend-icon=""
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ><template v-slot:label>
                    Payment date <span class="text-danger">*</span>
                  </template></v-text-field>
                </template>
                <v-date-picker
                    v-model="invoicePayment.payment_date"
                    no-title
                    @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.invoicePayment.payment_type.$error">This Field is required.</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-select
                  label="Payment type"
                  v-model="invoicePayment.payment_type"
                  :error="$v.invoicePayment.payment_type.$error"
                  :items="paymentMethods"
                  item-text="title"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Payment type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.invoicePayment.payment_type.$error">This Field is required.</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-text-field
                  @blur="checkEditableAmount"
                  type="number"
                  v-model="invoicePayment.amount"
                  :error="$v.invoicePayment.amount.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Amount <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.invoicePayment.amount.$error">This field is required.</span>
              <span class="text-danger" v-if="errors.amount" >* {{ errors.amount[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-file-input
                  v-model="invoicePayment.new_file"
                  :error="$v.invoicePayment.new_file.$error"
                  prepend-inner-icon="mdi-file"
                  prepend-icon=""
                  outlined
                  dense
              >
                <template v-slot:label>
                  File <span class="text-danger">*</span>
                </template>
              </v-file-input>
              <span class="text-danger" v-if="$v.invoicePayment.new_file.$error">This Field is required.</span>
            </v-col>

            <v-col cols="12" md="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="invoicePayment.description" >
              </ckeditor>
              <span class="text-danger" v-if="$v.invoicePayment.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
            v-if="!editable"
            :loading="loading"
            class="btn btn-primary"
            @click="createOrUpdate()"
        >
          {{edit ? "Update" : "Save"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import InvoicePaymentService from "@/services/invoice/payment/InvoicePaymentService";
import FinanceMixin from "@/mixins/FinanceMixin";

const invoicePayment = new InvoicePaymentService();

export default {
  name: "CreateAndUpdate",
  mixins: [FinanceMixin],
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      editable: false,
      menu: false,
      errors: [],
      amount: 0,
      invoicePayment: {
        title: '',
        transaction_id: '',
        invoice_id: '',
        payment_type: '',
        new_file: null,
        payment_date: '',
        amount: '0',
        description: '',
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
      paymentMethods: [
        {
          title: 'Bank',
          value: 'bank'
        },
        {
          title: 'SWIFT',
          value: 'swift'
        },
        {
          title: 'Cash',
          value: 'cash'
        },
        {
          title: 'Khalti',
          value: 'khalti'
        },
        {
          title: 'Esewa',
          value: 'esewa'
        },
        {
          title: 'Connect Ips',
          value: 'connect_ips'
        },

      ],
    }
  },
  validations: {
    invoicePayment: {
      title: { required },
      transaction_id: { required },
      new_file: { required :requiredIf(function () {
          return !this.edit;
        })
      },
      payment_type: { required },
      payment_date: { required },
      amount: { required },
      description: { required },
    }
  },
  computed: {
    invoiceId() {
      return this.$route.params.id;
    },
    totalAmount() {
      return parseFloat(this.invoice.invoice_total)
    },

  },
  methods: {
    checkAmount() {
      if(this.invoicePayment.amount > this.totalAmount) {
        this.$snotify.error("Amount should be less than invoice amount!!");
        return false;
      }
      return true;
    },

    checkEditableAmount() {
      if(this.invoice) {
        let amount = parseFloat(this.invoice.payment_amount) - parseFloat(this.amount)
        let paymentAmount =  parseFloat(this.invoicePayment.amount)
        let total =  parseFloat(this.invoice.invoice_total)
        this.editable = (amount+paymentAmount) > total;
      }
    },

    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },

    createInvoicePayment() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
      this.getInvoice();
    },

    editInvoicePayment(invoicePayment) {
      this.title = 'Update';
      this.dialog = true;
      this.edit = true;
      this.invoicePayment = invoicePayment;
      this.amount = invoicePayment.amount;
      this.getInvoice();
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {

       if(this.checkAmount()) {
         if (!this.edit) this._create();
         else this.update();
       }
      }
    },
    _create: function() {
      this.loading = true;
      invoicePayment
          .store(this.invoiceId, this.invoicePayment)
          .then(response => {
            this.$snotify.success(" invoice payment created successfully");
            this.closeDialog();
            this.getInvoice();
            this.$emit('refresh');
            this.$emit('refresh-invoice');
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update() {
      this.loading = true;
      invoicePayment
          .update(this.invoiceId,this.invoicePayment.id,this.invoicePayment)
          .then(response => {
            this.$snotify.success(" invoice payment updated successfully");
            this.closeDialog();
            this.getInvoice();
            this.$emit('refresh');
            this.$emit('refresh-invoice');
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.invoicePayment = {
        title: '',
        transaction_id: '',
        payment_type: '',
        new_file: null,
        payment_date: '',
        amount: '0',
        description: '',
      }
    },
  }
}
</script>
