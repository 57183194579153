<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
                  <span>View invoice </span>
                </h4>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \ View invoice
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <section v-if="invoice">
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <v-card-text>
                          <v-row class="ml-1 mr-2">
                            <v-col cols="6">
                              <img :src="mySiteSetting.logo_base64" width="150" alt="logo" />
                              <p v-if="mySiteSetting.pan_no"><b>PAN no:</b> {{mySiteSetting.pan_no}}</p>
                              <p v-if="mySiteSetting.vat_no"><b>VAT no:</b> {{mySiteSetting.vat_no}}</p>
                            </v-col>

                            <v-col cols="6" class="mt-5 text-right">
                                <h6 class="text-h5 mb-4">
                                  <b>Invoice #{{ invoice.invoice_no }}</b>
                                </h6>
                                <p class="mb-2">
                                  <b>Date Issued: </b>
                                  <span>{{ invoice.created_at }}</span>
                                </p>

                                <p class="mb-2">
                                  <b>Due Date: </b>
                                  <span>{{ invoice.due_date }}</span>
                                </p>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider />

                        <v-card-text >

                          <v-row class="ml-2 mr-2">
                            <v-col cols="6">
                              <h6 class="text-sm font-weight-medium mb-3">
                                Bill from:
                              </h6>
                              <p class="mb-1">
                                {{ invoice.payer_name }}
                              </p>
                              <p class="mb-1">
                                {{ invoice.payer_address }}
                              </p>
                              <p class="mb-1">
                                {{ invoice.payer_phone }}
                              </p>
                              <p class="mb-0">
                                {{ invoice.payer_email }}
                              </p>
                            </v-col>

                            <v-col cols="6" class="text-right">
                              <h6 class="text-sm font-weight-medium mb-3">
                                Invoice To:
                              </h6>
                              <p class="mb-1">
                                {{ invoice.invoiced_to }}
                              </p>
                              <p class="mb-1">
                                {{ invoice.invoice_address }}
                              </p>
                              <p class="mb-1">
                                {{ invoice.invoice_phone }}
                              </p>
                              <p class="mb-1">
                                {{ invoice.invoice_email }}
                              </p>
                              <p class="mb-1" v-if="invoice.abn">
                                <b>ABN no:</b> {{ invoice.abn }}
                              </p>
                              <p class="mb-0" v-if="invoice.acn">
                                <b>ACN no:</b> {{ invoice.acn }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider />

                        <table class="table">
                          <thead>
                            <tr>
                              <th >
                                Item
                              </th>
                              <th >
                                Quantity
                              </th>
                              <th  class="text-center">
                                Discount amount
                              </th>
                              <th  class="text-center">
                                Sub total
                              </th>
                              <th  class="text-center">
                                Total
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(item, i) in invoice.items" :key="i">
                            <td class="text-no-wrap">
                              {{ item.financial_title }}
                            </td>
                            <td class="text-no-wrap">
                              {{ item.quantity }}
                            </td>
                            <td class="text-center">
                              {{ item.discount_amount }}
                            </td>
                            <td class="text-center">
                              {{ invoice.currency_symbol }}  {{ item.sub_total }}
                            </td>
                            <td class="text-center">
                              {{ invoice.currency_symbol }} {{ item.item_price }}
                            </td>
                          </tr>
                          </tbody>
                        </table>

                        <v-card-text>
                          <v-row>
                            <v-col cols="6" class="pl-4">
                              <span v-if="payment_gateways && payment_gateways.description" v-html="payment_gateways.description"></span>
                            </v-col>
                            <v-col cols="6">
                              <table class="table w-150 float-right">
                                <tbody>
                                  <tr>
                                    <th>
                                      Subtotal:
                                    </th>
                                    <td class="p-2 text-right">
                                      <h6 class="text-sm font-weight-medium">
                                        {{ invoice.currency_symbol }}  {{ invoice.sub_total }}
                                      </h6>
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>
                                      Discount:
                                    </th>
                                    <td class="p-2 text-right">
                                      <h6 class="text-sm font-weight-medium">
                                        {{ invoice.currency_symbol }}  {{ invoice.discount_amount }}
                                      </h6>
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>
                                      Gst({{ mySiteSetting.tax_percentage || 0 }}%):
                                    </th>
                                    <td class="p-2 text-right">
                                      <h6 class="text-sm font-weight-medium">
                                        {{ invoice.currency_symbol }}  {{ invoice.tax_total || '0.00' }}
                                      </h6>
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>
                                      Total received:
                                    </th>
                                    <td class="p-2 text-right">
                                      <h6 class="text-sm font-weight-medium">
                                        {{ invoice.currency_symbol }}  {{ invoice.payment_amount || '0.00' }}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      Total remaining:
                                    </th>
                                    <td class="p-2 text-right">
                                      <h6 class="text-sm font-weight-medium">
                                        {{ invoice.currency_symbol }}   {{ remainingAmount }}
                                      </h6>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </v-col>
                          </v-row>

                        </v-card-text>

                        <v-divider class="mt-2" />

                        <v-card-text class="ml-3 mr-4" style="page-break-before:always">

                          <span v-if="mySiteSetting.agent_commission_note" v-html="mySiteSetting.agent_commission_note"></span>
                        </v-card-text>
                      </v-card>
                    </v-col>

                  </v-row>
                </section>
              </div>
              <div class="col-12 text-right">
                <v-btn class="mx-3 btn btn-primary text-white" @click="generateReport">
                  {{ invoice && invoice.file ? "Regenerate Invoice PDF" : "Generate Invoice PDF" }}
                </v-btn>
                <v-btn class="btn btn-primary text-white" @click="sendMail" v-if="invoice && invoice.file">
                  {{ invoice && invoice.is_send_mail ? "Resend invoice" : "Send invoice" }}
                </v-btn>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    :filename="'invoice.pdf'"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="landscape"
                    pdf-content-width="100%"
                    @progress="onProgress($event)"
                    ref="html2Pdf"
                >
                  <section slot="pdf-content">
                      <v-row v-if="invoice">
                        <v-col cols="12">
                          <v-card>
                            <v-card-text>
                              <v-row class="ml-1 mr-2">
                                <v-col cols="6">
                                  <img :src="mySiteSetting.logo_base64" width="150" alt="logo" />
                                  <p v-if="mySiteSetting.pan_no"><b>PAN no:</b> {{mySiteSetting.pan_no}}</p>
                                  <p v-if="mySiteSetting.vat_no"><b>VAT no:</b> {{mySiteSetting.vat_no}}</p>
                                </v-col>

                                <v-col cols="6" class="mt-5 text-right">
                                  <h6 class="text-h5 mb-4">
                                    <b>Invoice #{{ invoice.invoice_no }}</b>
                                  </h6>
                                  <p class="mb-2">
                                    <b>Date Issued: </b>
                                    <span>{{ invoice.created_at }}</span>
                                  </p>

                                  <p class="mb-2">
                                    <b>Due Date: </b>
                                    <span>{{ invoice.due_date }}</span>
                                  </p>
                                </v-col>
                              </v-row>
                            </v-card-text>

                            <v-divider />

                            <v-card-text>
                              <v-row class="ml-2 mr-2">
                                <v-col cols="6">
                                  <h6 class="text-sm font-weight-medium mb-3">
                                    Bill from:
                                  </h6>
                                  <p class="mb-1">
                                    {{ invoice.payer_name }}
                                  </p>
                                  <p class="mb-1">
                                    {{ invoice.payer_address }}
                                  </p>
                                  <p class="mb-1">
                                    {{ invoice.payer_phone }}
                                  </p>
                                  <p class="mb-0">
                                    {{ invoice.payer_email }}
                                  </p>
                                </v-col>

                                <v-col cols="6" class="text-right">
                                  <h6 class="text-sm font-weight-medium mb-3">
                                    Invoice To:
                                  </h6>
                                  <p class="mb-1">
                                    {{ invoice.invoiced_to }}
                                  </p>
                                  <p class="mb-1">
                                    {{ invoice.invoice_address }}
                                  </p>
                                  <p class="mb-1">
                                    {{ invoice.invoice_phone }}
                                  </p>
                                  <p class="mb-0">
                                    {{ invoice.invoice_email }}
                                  </p>
                                  <p class="mb-1" v-if="invoice.abn">
                                    <b>ABN no:</b> {{ invoice.abn }}
                                  </p>
                                  <p class="mb-0" v-if="invoice.acn">
                                    <b>ACN no:</b> {{ invoice.acn }}
                                  </p>
                                </v-col>
                              </v-row>
                            </v-card-text>

                            <v-divider />

                            <table class="table">
                              <thead>
                                <tr>
                                  <th >
                                    Item
                                  </th>
                                  <th >
                                    Quantity
                                  </th>
                                  <th  class="text-center">
                                    Discount amount
                                  </th>
                                  <th  class="text-center">
                                    Sub total
                                  </th>
                                  <th  class="text-center">
                                    Total
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr v-for="(item, i) in invoice.items" :key="i">
                                  <td class="text-no-wrap">
                                    {{ item.financial_title }}
                                  </td>
                                  <td class="text-no-wrap">
                                    {{ item.quantity }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.discount_amount }}
                                  </td>
                                  <td class="text-center">
                                    {{ invoice.currency_symbol }}  {{ item.sub_total }}
                                  </td>
                                  <td class="text-center">
                                    {{ invoice.currency_symbol }}  {{ item.item_price }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <v-card-text>
                              <v-row class="ml-2 mr-2">
                                <v-col cols="6">
                                  <span v-if="payment_gateways && payment_gateways.description" v-html="payment_gateways.description"></span>
                                </v-col>
                                <v-col cols="6">
                                  <table class="table w-150 float-right">
                                    <tbody>
                                      <tr>
                                        <th>
                                          Subtotal:
                                        </th>
                                        <td class="p-2 text-right">
                                          <h6 class="text-sm font-weight-medium">
                                            {{ invoice.currency_symbol }}  {{ invoice.sub_total }}
                                          </h6>
                                        </td>
                                      </tr>

                                      <tr>
                                        <th>
                                          Discount:
                                        </th>
                                        <td class="p-2 text-right">
                                          <h6 class="text-sm font-weight-medium">
                                            {{ invoice.currency_symbol }}  {{ invoice.discount_amount }}
                                          </h6>
                                        </td>
                                      </tr>

                                      <tr>
                                        <th>
                                          Gst({{ mySiteSetting.tax_percentage || 0 }}%):
                                        </th>
                                        <td class="p-2 text-right">
                                          <h6 class="text-sm font-weight-medium">
                                            {{ invoice.currency_symbol }}  {{ invoice.tax_total || '0.00' }}
                                          </h6>
                                        </td>
                                      </tr>

                                      <tr>
                                        <th>
                                          Total received:
                                        </th>
                                        <td class="p-2 text-right">
                                          <h6 class="text-sm font-weight-medium">
                                            {{ invoice.currency_symbol }}  {{ invoice.payment_amount || '0.00' }}
                                          </h6>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>
                                          Total remaining:
                                        </th>
                                        <td class="p-2 text-right">
                                          <h6 class="text-sm font-weight-medium">
                                            {{ invoice.currency_symbol }}   {{ remainingAmount }}
                                          </h6>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </v-col>
                              </v-row>
                            </v-card-text>

                            <v-divider class="mt-2" />

                            <v-card-text class="ml-3 mr-4" style="page-break-before:always">
                                <span v-if="mySiteSetting.agent_commission_note" v-html="mySiteSetting.agent_commission_note"></span>
                            </v-card-text>
                          </v-card>
                        </v-col>

                      </v-row>
                  </section>
                </vue-html2pdf>
              </div>
            </div>

            <div class="row">

              <div class="col-12">
                <invoice-payment ref="invoice-payment" @refresh="getInvoice" :invoice="invoice"></invoice-payment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import InvoiceService from "@/services/invoice/InvoiceService";
import InvoicePayment from "@/view/pages/view/invoice/payment/InvoicePayment.vue";
import FinanceMixin from "@/mixins/FinanceMixin";
import PaymentGatewaySettingService from "@/services/cms/site-setting/payment-gateway/PaymentGatewaySettingService";

const invoiceService = new InvoiceService();
const paymentGatewayService = new PaymentGatewaySettingService();

export default {
  name: "ViewInvoice",
  mixins: [FinanceMixin],
  components: {
    InvoicePayment,
    VueHtml2pdf
  },

  data() {
    return  {
      payment_gateways: null
    }
  },

  computed: {
    remainingAmount() {
      return parseFloat(parseFloat(this.invoice.invoice_total) - parseFloat(this.invoice.payment_amount))
    }
  },

  mounted() {
    this.getInvoice();
    this.getOfflinePayment();
  },

  methods: {
    sendMail() {
      if(this.invoice && this.invoice.application_id && !this.invoice.finance_mail) {
        this.$snotify.error("No finance email found");
        return
      }
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            invoiceService.sendInvoice(this.invoice.id).then((response) => {
              if (response.data.status == "OK") {
                this.$snotify.success("Send invoice mail successfully ");
                this.getInvoice()
              }
            }).catch(() => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },

    getOfflinePayment() {
      paymentGatewayService.findByType('offline').then(response => {
        this.payment_gateways = response.data.paymentGatewaySetting
      })
    },

    generateReport() {
      this.$refs['html2Pdf'].generatePdf()
    },

    onProgress() {
      let pdf_file = this.$refs['html2Pdf'].pdfFile;
      if (pdf_file) {
        let url = new URL(pdf_file);
        this.convertBlobToBase64(url);
      }
    },

    convertBlobToBase64(url) {
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      const blobToBase64 = () => {
        return new Promise(resolve => {
          request.onload = function() {
            const reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onloadend = () => {
              resolve(reader.result);
            };

          };
        });
      };
      request.send();

      blobToBase64().then(res => {
        this.convertBlobURLToFIle(res);
      });
    },

    convertBlobURLToFIle(base64Pdf) {
      if (base64Pdf.startsWith('data:application/pdf')) {
        let arr = base64Pdf.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            str = atob(arr[arr.length - 1]),
            n = str.length,
            u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = str.charCodeAt(n);
        }
        let file = new File([u8arr], "invoice.pdf",  {type:mime});
        this.generateInvoicePdf(file);
      }
    },

    generateInvoicePdf(myFile) {
      let formData = new FormData();
      formData.append('pdf_file', myFile);
      invoiceService.generateInvoice(this.invoice_id, formData).then(response => {
        if(response.data.status === "OK") {
          this.$snotify.success(`${this.invoice && this.invoice.file ? "Regenerate invoice PDF" : "Generate invoice PDF"} successfully`);
          this.getInvoice();
        } else {
          this.$snotify.error("Oops something went wrong");

        }
      }).catch(() => {});
    }
  }
}
</script>
<style>
.w-150 {
  width: 220px;
}
</style>
